<template>
  <div class="into1">
    <!-- <van-swipe style="height: 303px;" :autoplay="3000" indicator-color="white" vertical>
      <van-swipe-item><img src="../../assets/images/banner-首页.png" alt="" width="100%" height="100%"/></van-swipe-item>
      <van-swipe-item><img src="../../assets/images/banner-自由练习.png" alt="" width="100%" height="100%"/></van-swipe-item>
    </van-swipe> -->
    <!-- class="listWrapper" -->
    <div style="background-color:#fff;">
      <van-row>
        <van-col span="24">
          <div><img src="../../assets/images/banner-pay.png" width="100%" /></div>
          <div style="clear:both;"></div>
          <!-- <h2 class="h2list" style="margin-left:0.25rem; color:#000; margin-top:20px;">{{iy}}</h2> -->
          <div style="position:relative; top:-8vh; left:8vw; color:#fff; font-weight:600;">{{iy}}</div>
        </van-col>
      </van-row>
      <div style="clear:both;"></div>
      <ul style="margin-top:0rem; margin-left:0.2rem;">
        <li>支付金额：<span style="color:red;">{{this.price}}元</span></li>
        <li>支付明细：<span style="color:red;">技能家尊贵会员</span></li>
        <li>支付说明：<span style="color:red;">支付成功后自动激活</span></li>
      </ul>
      <van-row>
        <van-col span="24" style="padding:0.25rem 0.25rem;">
          <van-col span="6" style="margin-left:0.2rem;">支付方式：</van-col>
          <van-col v-if="checkPlatForm == 1 || checkPlatForm == 2" span="17" style="">
            <van-col span="3" style="margin-left:0.2rem;"><img src="../../assets/images/wachat.png" width="35re" />
            </van-col>
            <van-col span="11" style="margin-left:0.2rem; line-height:0.6rem; text-align:left;">支付宝支付</van-col>
            <van-col span="2" style="margin-left:1rem; margin-top:0.1rem;">
              <van-radio-group v-model="checked">
                <van-radio name="1"></van-radio>
              </van-radio-group>
            </van-col>
          </van-col>
        </van-col>
        <van-col span="24" style="padding:0.25rem 0.25rem;">
          <van-col span="6" style="margin-left:0.2rem;"></van-col>
          <van-col v-if="checkPlatForm == 0 || checkPlatForm == 2" span="17" style="">
            <van-col span="3" style="margin-left:0.2rem;"><img src="../../assets/images/alipay.png" width="35re">
            </van-col>
            <van-col span="11" style="margin-left:0.2rem; line-height:0.6rem; text-align:left;">微信支付</van-col>
            <van-col span="2" style="margin-left:1rem; margin-top:0.1rem;">
              <van-radio-group v-model="checked">
                <van-radio name="2"></van-radio>
              </van-radio-group>
            </van-col>
          </van-col>
        </van-col>
      </van-row>
    </div>


    <div style="clear:both; width:100%; margin-top:1rem;"></div>
    <div style="text-align: center; margin:0 auto; overflow: hidden; width: 100%;">
      <div style="width:80%;  margin:0 auto;">
        <van-button round color="#3F7EEF" size="large" style="" @click="clkZfb">支付</van-button>
      </div>
    </div>

    <!-- <van-tag type="primary">{{jgName}}</van-tag> -->

    <!-- <van-divider content-position="left" dashed>归属:{{jgName}}</van-divider>
    <div class="bg002">
      <ul>
        <li v-if="checkPlatForm == 0 || checkPlatForm == 2"><a class="button" @click="clkWach">微信支付</a></li>
        <li v-if="checkPlatForm == 1 || checkPlatForm == 2"><a class="button" @click="clkZfb">支付宝支付</a></li>
      </ul>
      <p><img src="../../assets/logo.png" width="40%" /></p>
    </div> -->

    <van-dialog v-model="isShow" show-cancel-button :beforeClose="beforeClose" style="left:50vw;">
      <template v-slot:title>
        <h3 style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">请先绑定您的个人信息</h3>
      </template>
      <van-field v-model="userName" label="姓名" placeholder="请输入您真实的姓名" />
      <van-field v-model="userPhone" label="手机号" placeholder="请输入手机号" type="number" maxlength="11" />
      <template>
        <span
          style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">支付成功后，您的授权信息将由短信发送给您，请注意查收</span>
      </template>

    </van-dialog>

  </div>
</template>

<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
import { ref } from 'vue'
import { createApp } from 'vue';
export default {
  // name: "帛钥智能 - 渠道支付",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      high: ['rrt', 'uui'], //高风险区数据
      mid: [], //低风险区域
      dialogVisible: false,
      isShow: false,
      userName: '',
      userPhone: '',
      visible: false,
      jgName: "",
      checkPlatForm: 2, //媒介检测，0微信，1，支付宝，2浏览器
      orderId: "1dsf2343234324324",
      checked: 1,
      price: 98,
      userId: 0,
      userInstionId:0,
      souUserStatus: false,
      ttr: [],
      iy: this.$route.query.jg,
    };
  },

  setup() {
    const checked = ref('1');
    return { checked };
  },

  // computed: {

  // },

  created() {
    this.jgNames()
    this.IsWeixinOrAlipay()
  },

  methods: {
    clkZfb() {
      // 绑定用户
      this.clkWach()
    },

    IsWeixinOrAlipay() {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log("********************** WeiXIN模式")
        this.checkPlatForm = 0;
        return "WeiXIN";
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == 'alipayclient') {
        console.log("********************** Alipay模式")
        this.checkPlatForm = 1;
        return "Alipay";
      }
      //哪个都不是
      console.log("********************** 普通浏览器打开模式")
      this.checkPlatForm = 2;
      return "false";
    },

    jgNames() {
      // http://localhost:8081/#/into1?jg=0002
      if (this.$route.query.jg == '0001') {
        return this.jgName = "职通宝机构"
      } else if (this.$route.query.jg == '0002') {
        return this.jgName = "恒山机构"
      } else if (this.$route.query.jg == '0003') {
        return this.jgName = "华山机构"
      } else if (this.$route.query.jg == '0004') {
        return this.jgName = "嵩山机构"
      } else {
        return this.jgName = "其他机构..."
      }
    },

    clkWach() {
      this.isShow = !this.isShow
      // if(this.checked == 2){
      //   Dialog.alert({ //微信
      //           // message: this.checked + "微信"
      //           message: "暂未开通，请选择支付宝进行支付！"
      //         }).then(() => {
      //           // on close
      //         })
      // }else{
      //   this.isShow = !this.isShow
      // }
      
    },

    beforeClose(action, done) {
      console.log("***********ddd***********")
      console.log(this.$route.query)
      console.log(this.$route.params)
      if (action === 'confirm') {
        console.log("action = ", action)
        if (!this.userName || !this.userPhone) {
          this.$toast("请输入姓名及电话号码")
          done(false) //不关闭弹框
        } else {
          // let jg_key = ''
          // if(this.$route.query.jg){
          //   jg_key = this.$route.query.jg
          // }else{
          //   jg_key = '技能家机构'
          // }
          var data = new URLSearchParams();
          data.append('username', this.userName);   // 添加数据
          data.append('userphone', this.userPhone);   // 添加数据this.$route.query.jg
          data.append('institution_code', this.$route.query.jg);
          this.submitFormUser(data)
          //setTimeout(done, 1000)
        }

      } else if (action === 'cancel') {
        // console.log("action = ", action)
        // this.$toast("取消绑定！")
        done(true) //关闭
      }
    },
    //发送绑定, 如果用户不存在则新增用户
    submitFormUser(params) {
      
      api.postCovInfo(params).then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.$toast("绑定成功！")
          console.log("绑定成功！");
          console.log(res.data);
          this.userId = res.data.userId
          this.userInstionId = res.data.userInstionId
          this.souUserStatus = true
          this.ttr = [
            {price: this.price},
            {userId: this.userId},
            {jg: this.userInstionId}
          ]
          //平台检测，浏览器，微信，支付宝
          // 普通浏览器环境下
          if (this.checkPlatForm == 2) {
            if (this.checked == 1) { //支付宝
              this.$router.push({ path: 'AliPay', query: { price: this.ttr }});
              // Dialog.alert({
              //   message: this.checked + "支付宝"
              //   }).then(() => {
              // // on close
              // })
            } else {
              this.$router.push({path: 'WxPay', query: { price: this.ttr }});
              // Dialog.alert({ //微信
              //   // message: this.checked + "微信"
              //   message: "暂未开通，请选择支付宝进行支付！"
              // }).then(() => {
              //   // on close
              // })
              // return
              // window.location.href('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://sakhr8081.cpolar.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome')
            }

          }
          // 支付宝环境下
          if (this.checkPlatForm == 1) {
            if (this.checked == 1) {
              this.$router.push({ path: 'AliPay', query: { price: this.ttr } });
              // Dialog.alert({
              //   message: this.checked + "支付宝"
              //   }).then(() => {
              // // on close
              // })
            }
          }
          // 微信环境下
          if (this.checkPlatForm == 0) {
            // this.$router.push({ path: 'Home', query: { price: this.ttr } }); //不适用这一条

              // Dialog.alert({ //微信
              //   // message: this.checked + "微信"
              //   message: "微信内支付暂未开通,建议使用浏览器扫码微信支付，或者选择支付宝进行支付！"
              // }).then(() => {
              //   // on close
              // })
              //return
            //先创建用户支付订单
            this.$router.push({ path: 'WxPay2', query: { price: this.ttr } });
          }
          // this.sqfk()//微信授权
        } else {
          this.$toast("绑定失败！")
          console.log("绑定失败！");
        }
      });
    },

    // 判断是否中文
    isChinese(temp) {
      var re = /[^\u4E00-\u9FA5]/;
      if (re.test(temp)) return false;
      return true;
    },

  }
};
</script>

<style lang='less' scoped>
.listWrapper{
  background:red;
  height: 24vh; 
  line-height:43vh; 
  color:#000;
}
.h2list{
  display: inline;

}

.into1 {
  font-size: 0.3rem;
}

.into1 ul li {
  padding: 0.25rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: auto;
  overflow: hidden;
  text-align: center;
  // background-color: #39a9ed;
}

.into1 {
  background: #F5F8FA;
}

.bg {
  height: 3.6rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.bg001 {
  height: 5rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding: 0.25rem 0.25rem;
  color: #D43030;
}

.bg002 {
  background: #fff;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  // padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 8rem;
  margin-bottom: 0.2rem;

  p {
    margin-top: 1rem;
    text-align: center;
    color: #777;
  }
}

.bg002 ul li {
  margin: 0.8rem 0.15rem;
  text-align: center;
}

.bg002 .button {
  background-color: #4D88F2;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.25rem;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg002 .button:hover {
  background-color: #4e8ebf;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
}

.content {
  background: #ECD773;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 10rem;
  margin-bottom: 0.2rem;

  .th {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .th:before {
    display: inline-block;
    width: 0.1rem;
    height: 0.3rem;
    margin-right: 0.1rem;
    background-color: #4169e2;
    content: "";
    vertical-align: middle;
  }

  .title {
    font-size: 0.3rem;
    margin-bottom: 0.3rem;

    img {
      width: 0.4rem;
      vertical-align: top;
    }
  }

  .list {
    li {
      margin-bottom: 0.2rem;
      color: #333;
      background: #f7f7f7;
      padding: 0.1rem;
      border-radius: 0.1rem;
      letter-spacing: 0.01rem;
      line-height: 0.5rem;
    }
  }
}
</style>